<template>
  <v-dialog
    v-model="active"
    :dark="false"
    :light="true"
    :max-width="max_width ? max_width : '600px'"
    no-click-animation
    persistent
  >
    <v-card class="mx-auto pb-1" max-width="max_width ? max_width : '600px'">
      <v-toolbar flat color="black" :dark="true">
        <v-toolbar-title>Edytuj kategorie</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-form ref="formName">
        <v-row class="mx-4 my-2 pa-auto">
          <v-col lg="12" md="12" sm="12" cols="12">
            <v-text-field
              v-model="editedCategory.name"
              label="Nazwa kategorii"
              :rules="lengthRule(1, 255)"
              maxlength="255"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <v-divider></v-divider>
      <v-divider></v-divider>

      <v-row class="mx-4 my-2 pa-auto">
        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          outlined
          :x-large="true"
          v-tilt
          @click="closeDialog()"
        >
          Anuluj
        </v-btn>
        <v-spacer />
        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          :x-large="true"
          v-tilt
          @click="editCategory()"
        >
          Zapisz
        </v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'EditCategory',
    props: {
      category: Object,
      max_width: String,
    },
    data: () => ({
      active: true,

      editedCategory: {
        name: '',
      },

      preventBtn: false,
    }),
    watch: {
      'editedCategory.name': function (val) {
        if (val != this.category.name) {
          this.preventBtn = false;
        } else {
          this.preventBtn = true;
        }
      },
    },
    methods: {
      lengthRule: function (min_length, max_length) {
        return [
          v =>
            new RegExp(`^.{${min_length},${max_length}}$`).test(v) ||
            `Pole musi zawierac od ${min_length} do ${max_length} znaków`,
        ];
      },
      editCategory: function () {
        if (!this.$refs.formName.validate()) {
          return this.$emit('message', {
            content: 'Nie podano nazwy kategorii',
            type: 'error',
            timeout: 2000,
          });
        }

        this.$emit('edit', this.editedCategory);
      },
      closeDialog: function () {
        this.$emit('deactive');
      },
    },
    mounted() {
      this.editedCategory = Object.assign({}, this.category);
    },
  };
</script>

<style scoped>
  .v-btn--outlined {
    border: thin solid #ffc400 !important;
  }
</style>
