<template>
  <v-dialog v-model="isActive" persistent :width="width">
    <v-card>
      <v-toolbar flat color="black" dark>
        <v-toolbar-title v-if="!set"> Dodaj zestaw </v-toolbar-title>
        <v-toolbar-title v-else> Edytuj zestaw </v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-card-text class="py-5 grey--text text--darken-4">
        <v-row justify="center" align="center">
          <AddEditSetComponent
            ref="addEditSet"
            :set="set"
            :items="items"
            :categories="categories"
          ></AddEditSetComponent>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          outlined
          v-tilt
          x-large
          @click="isActive = false"
        >
          Anuluj
        </v-btn>

        <v-spacer />

        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          v-tilt
          x-large
          @click="submit"
        >
          {{ !set ? 'Dodaj' : 'Edytuj' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import AddEditSetComponent from './AddEditSetComponent.vue';

  export default {
    name: 'AddEditSetDialog',
    components: {
      AddEditSetComponent,
    },
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      set: {
        type: Object,
      },
      items: {
        type: Array,
        default: () => [],
      },
      categories: {
        type: Array,
        default: () => [{ name: 'Ogólna', category_id: null }],
      },
      width: {
        type: String,
        default: '820px',
      },
    },
    data: () => ({}),
    watch: {},
    computed: {
      isActive: {
        get() {
          return this.value;
        },
        set(val) {
          if (val === false) this.$refs.addEditSet.initSet();
          this.$emit('input', val);
        },
      },
    },
    methods: {
      submit: function () {
        if (!this.$refs.addEditSet.isValid)
          return this.$refs.addEditSet.infoSnackBar({
            message: `Uzupełnij wszystkie dane`,
            status: 'error',
          });

        this.$emit('submit', this.$refs.addEditSet.setCopy);
      },
    },
  };
</script>

<style scoped>
  .v-btn--outlined {
    border: thin solid #ffc400 !important;
  }
  .v-btn--pretend--disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
</style>
<style></style>
