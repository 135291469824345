<template>
  <v-dialog
    v-model="active"
    :dark="false"
    :light="true"
    :max-width="max_width ? max_width : '600px'"
    no-click-animation
    persistent
  >
    <v-card class="mx-auto pb-1" max-width="max_width ? max_width : '600px'">
      <v-toolbar flat color="black" :dark="true">
        <v-toolbar-title>Dodaj kategorie</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-form ref="formName">
        <v-row class="mx-4 my-2 pa-auto">
          <v-col lg="8" md="8" sm="8" cols="12">
            <v-text-field
              v-model="name"
              label="Nazwa kategorii"
              :rules="lengthRule(1, 255)"
              maxlength="255"
            ></v-text-field>
          </v-col>
          <v-col lg="4" md="4" sm="4" cols="12">
            <v-select
              v-model="type"
              :items="category_types"
              label="Typ"
              hide-details
              item-text="name"
              item-value="type"
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
      <v-divider></v-divider>
      <v-divider></v-divider>

      <v-row class="mx-4 my-2 pa-auto">
        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          outlined
          :x-large="true"
          v-tilt
          @click="closeDialog()"
        >
          Anuluj
        </v-btn>
        <v-spacer />
        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          :x-large="true"
          v-tilt
          @click="addCategory()"
        >
          Zapisz
        </v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'AddCategory',
    props: {
      max_width: String,
    },
    data: () => ({
      active: true,

      name: '',
      type: 'item',
      category_types: [
        {
          name: 'Przedmiot',
          type: 'item',
        },
        {
          name: 'Zestaw',
          type: 'set',
        },
      ],

      preventBtn: true,
    }),
    watch: {
      name: function (val) {
        if (val.length) {
          this.preventBtn = false;
        } else {
          this.preventBtn = true;
        }
      },
    },
    methods: {
      lengthRule: function (min_length, max_length) {
        return [
          v =>
            new RegExp(`^.{${min_length},${max_length}}$`).test(v) ||
            `Pole musi zawierac od ${min_length} do ${max_length} znaków`,
        ];
      },
      addCategory: function () {
        if (!this.$refs.formName.validate()) {
          return this.$emit('message', {
            content: 'Nie podano nazwy kategorii',
            type: 'error',
            timeout: 2000,
          });
        }

        this.$emit('add', {
          name: this.name,
          type: this.type,
        });
      },
      closeDialog: function () {
        this.$emit('deactive');
      },
    },
    mounted() {},
  };
</script>

<style scoped>
  .v-btn--outlined {
    border: thin solid #ffc400 !important;
  }
</style>
