<template>
  <v-container fluid class="grey lighten-4 bottom-spacer" style="height: 100%">
    <v-card-title class="px-0">
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>Cennik</v-list-item-title>
          <v-list-item-subtitle>Definiuj przedmioty oraz zestawy</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-title>

    <v-card class="mx-auto mb-10" color="grey darken-4" dark v-if="isAdmin">
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-4">Dane klienta</div>
          <v-list-item-title class="headline mb-1">
            {{ user.name }} {{ user.surname }}
          </v-list-item-title>
          <v-list-item-subtitle class="mt-3"> Telefon: {{ user.phone }} </v-list-item-subtitle>
          <v-list-item-subtitle class="mt-3"> Email: {{ user.email }} </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-avatar size="80" color="amber">
          <span class="white--text headline">
            {{ user.name.substr(0, 1) }}{{ user.surname.substr(0, 1) }}
          </span>
        </v-list-item-avatar>
      </v-list-item>
    </v-card>

    <v-expansion-panels v-model="panelCategories" id="panelCategories">
      <v-expansion-panel>
        <v-expansion-panel-header class="headline font-weight-bold">
          Kategorie
          <v-tooltip top max-width="460">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="ml-2 panel-header-flex-fix" small>
                mdi-information
              </v-icon>
            </template>
            <span>
              Dodaj kategorie do listy kategorii, aby móc korzystać z niej podczas dodawania
              przedmiotów lub zestawów.
            </span>
          </v-tooltip>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-data-table
            :headers="headersCategories"
            :items="categoriesData"
            item-key="category_id"
            class="elevation-1"
            :search="searchCategories"
            :custom-filter="customFilterCategories"
            :loading="loadingCategories"
            :loading-text="loading_text"
            :footer-props="footerProps"
            sortBy="name"
            :sortDesc="false"
          >
            <template v-slot:top>
              <v-row class="mx-auto" align="center">
                <v-col lg="4" md="4" sm="4" cols="12">
                  <v-btn
                    color="primary"
                    dark
                    class="mx-2 mt-auto"
                    @click="addCategoryDialog = true"
                  >
                    Dodaj kategorię
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-col>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <v-select
                    v-model="filtersCategories.type"
                    :items="categoryTypesSelect"
                    label="Typ"
                    hide-details
                    item-text="name"
                    item-value="type"
                    dense
                  ></v-select>
                </v-col>
                <v-col lg="5" md="5" sm="5" cols="12">
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="filtersCategories.search"
                    append-icon="mdi-magnify"
                    label="Szukaj (nazwa kategorii)"
                    single-line
                    hide-details
                    class="mx-2 pb-3"
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>

            <template v-slot:[`item.edit`]="{ item }">
              <v-btn
                color="blue"
                outlined
                @click="
                  editedCategory = item;
                  editCategoryDialog = true;
                "
              >
                Edytuj
              </v-btn>
            </template>

            <template v-slot:[`item.delete`]="{ item }">
              <v-btn color="error" @click="removeCategory(item.category_id)"> Usuń </v-btn>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <br />

    <v-expansion-panels v-model="panelItems" id="panelItems">
      <v-expansion-panel>
        <v-expansion-panel-header class="headline font-weight-bold">
          Przedmioty
          <v-tooltip top max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="ml-2 panel-header-flex-fix" small>
                mdi-information
              </v-icon>
            </template>
            <span>
              Dodaj przedmiot do listy przedmiotów, aby móc korzystać z niego podczas dodawania
              zlecenia. Cena za transport przedmiotu zostanie wyznaczona przez administratora, mimo
              to system pozwala zlecać transport na przedmiotu jeszcze nie wycenione.
            </span>
          </v-tooltip>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-data-table
            :headers="headersItems"
            :items="items"
            item-key="item_id"
            class="elevation-1"
            :search="searchItems"
            :custom-filter="customFilterItems"
            :loading="loadingItems"
            :loading-text="loading_text"
            :footer-props="footerProps"
            sortBy="name"
            :sortDesc="false"
          >
            <template v-slot:top>
              <v-row class="mx-auto" align="center">
                <v-col lg="4" md="4" sm="4" cols="12">
                  <v-btn
                    color="primary"
                    dark
                    class="mx-2 mt-auto"
                    @click="addEditItemDialog = true"
                  >
                    Dodaj przedmiot
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-col>
                <v-col lg="3" md="3" sm="3" cols="12">
                  <v-select
                    v-model="filtersItems.category"
                    :items="itemCategoriesSelect"
                    label="Kategoria"
                    hide-details
                    item-text="name"
                    item-value="category_id"
                    :loading="loadingCategories"
                    dense
                  ></v-select>
                </v-col>
                <v-col lg="5" md="5" sm="5" cols="12">
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="filtersItems.search"
                    append-icon="mdi-magnify"
                    label="Szukaj (nazwa przedmiotu)"
                    single-line
                    hide-details
                    class="mx-2 pb-3"
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>

            <template v-slot:[`item.category_id`]="{ item }">
              {{ itemCategories.find(el => el.category_id == item.category_id).name }}
            </template>

            <template v-slot:[`item.edit`]="{ item }">
              <v-btn color="blue" outlined @click="editItemButton(item)"> Edytuj </v-btn>

              <v-tooltip top v-if="!item.isAccepted">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="error" dark v-bind="attrs" v-on="on" icon>
                    <v-icon> mdi-currency-usd </v-icon>
                  </v-btn>
                </template>
                <span v-if="isAdmin">
                  Cena niezweryfikowana przez administratora. Aby zatwierdzić cenę należy edytować
                  przedmiot i kliknąć przycisk edytuj
                </span>
                <span v-else>Cena niezweryfikowana przez administratora</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.delete`]="{ item }">
              <v-btn color="error" @click="removeItem(item.item_id)"> Usuń </v-btn>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <br />

    <v-expansion-panels v-model="panelSets" id="panelSets">
      <v-expansion-panel>
        <v-expansion-panel-header class="headline font-weight-bold">
          Zestawy
          <v-tooltip top max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="ml-2 panel-header-flex-fix" small>
                mdi-information
              </v-icon>
            </template>
            <span>
              Dodaj przedmioty do zestawów, aby móc przyśpieszyć składanie zamówień (podczas
              składania zamówienia będziesz mógł wybrać wariant dla każdego przedmiotu).
            </span>
          </v-tooltip>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-data-table
            :headers="headersSets"
            :items="sets"
            item-key="item_id"
            class="elevation-1"
            :search="searchSets"
            :custom-filter="customFilterSets"
            :loading="loadingSets"
            :loading-text="loading_text"
            :footer-props="footerProps"
            sortBy="name"
            :sortDesc="false"
          >
            <template v-slot:top>
              <v-row class="mx-auto" align="center">
                <v-col lg="4" md="4" sm="4" cols="12">
                  <v-btn color="primary" dark class="mx-2 mt-auto" @click="addEditSetDialog = true">
                    Dodaj zestaw
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-col>

                <v-col lg="3" md="3" sm="3" cols="12">
                  <v-select
                    v-model="filtersSets.category"
                    :items="setCategoriesSelect"
                    label="Kategoria"
                    hide-details
                    item-text="name"
                    item-value="category_id"
                    :loading="loadingCategories"
                    dense
                  ></v-select>
                </v-col>

                <v-col lg="5" md="5" sm="5" cols="12">
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="filtersSets.search"
                    append-icon="mdi-magnify"
                    label="Szukaj (nazwa zestawu)"
                    single-line
                    hide-details
                    class="mx-2 pb-3"
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>

            <template v-slot:[`item.category_id`]="{ item }">
              {{ setCategories.find(el => el.category_id == item.category_id).name }}
            </template>

            <template v-slot:[`item.edit`]="{ item }">
              <v-btn color="blue" outlined @click="editSetButton(item)"> Edytuj </v-btn>
            </template>

            <template v-slot:[`item.delete`]="{ item }">
              <v-btn color="error" @click="removeSet(item.set_id)"> Usuń </v-btn>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- modal progress circular -->
    <v-dialog v-model="loadingProgress" fullscreen>
      <v-container fluid fill-height style="background-color: rgba(255, 255, 255, 0.5)">
        <v-layout justify-center align-center>
          <v-progress-circular indeterminate color="amber"> </v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>

    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar"
      :color="snackbarType"
      :multi-line="true"
      :timeout="timeout"
      content-class="d-inline-flex"
    >
      {{ snackbarMessageList[0] ? snackbarMessageList[0].content : '' }}
      <v-spacer />
      <v-btn :color="snackbarButtonColor" text @click="snackbar = false"> Zamknij </v-btn>
    </v-snackbar>

    <!-- add category component -->
    <AddCategory
      v-if="addCategoryDialog"
      @deactive="
        addCategoryDialog = false;
        resetSnackbar();
      "
      @message="message"
      @add="addCategory"
    ></AddCategory>

    <!-- edit category component -->
    <EditCategory
      v-if="editCategoryDialog"
      :category="editedCategory"
      @deactive="
        editCategoryDialog = false;
        resetSnackbar();
      "
      @message="message"
      @edit="editCategory"
    ></EditCategory>

    <!-- add/edit item component -->
    <AddEditItemDialog
      v-model="addEditItemDialog"
      :categories="itemCategories"
      :item="editingItem"
      :permissions="permissions"
      @input="editingItem = null"
      @submit="addOrEditItem"
    ></AddEditItemDialog>

    <!-- add/edit set component -->
    <AddEditSetDialog
      v-model="addEditSetDialog"
      :set="editingSet"
      :categories="setCategories"
      :items="items"
      @input="editingSet = null"
      @submit="addOrEditSet"
    ></AddEditSetDialog>

    <!-- confirm dialog -->
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import ConfirmDialog from '../../components/Confirm.vue';
  import AddCategory from '../../components/AddCategory.vue';
  import EditCategory from '../../components/EditCategory.vue';
  import AddEditItemDialog from '../../components/PriceTable/AddEditItemDialog.vue';
  import AddEditSetDialog from '../../components/PriceTable/AddEditSetDialog.vue';

  export default {
    name: 'priceTable',
    components: {
      ConfirmDialog,
      AddCategory,
      EditCategory,
      AddEditItemDialog,
      AddEditSetDialog,
    },
    data: () => ({
      snackbar: false,
      snackbarMessageList: [],

      user: {
        name: '',
        surname: '',
        phone: '',
        email: '',
        hard_furniture: 1,
        upholstered_furniture: 0,
        chairs: 0,
        tables: 0,

        paid_collection: 0,
      },

      loadingProgress: false,
      loading_text: 'Ładowanie danych... Proszę czekać',
      footerProps: {
        showFirstLastPage: true,
        itemsPerPageText: '',
        itemsPerPageOptions: [20, 50, 100, -1],
      },

      categoryTypesSelect: [
        {
          name: 'Przedmiot',
          type: 'item',
        },
        {
          name: 'Zestaw',
          type: 'set',
        },
      ],
      itemCategories: [],
      setCategories: [],
      filtersCategories: {
        search: '',
        type: 'item',
      },
      headersCategories: [
        {
          text: 'Nazwa kategorii',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Edytuj',
          sortable: false,
          filterable: false,
          value: 'edit',
        },
        {
          text: 'Usuń',
          sortable: false,
          filterable: false,
          value: 'delete',
        },
      ],
      loadingCategories: true,
      panelCategories: undefined,

      items: [],
      filtersItems: {
        search: '',
        category: -1,
      },
      headersItems: [
        {
          text: 'Nazwa przedmiotu',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Kategoria',
          sortable: true,
          filterable: false,
          value: 'category_id',
        },
        {
          text: 'Edytuj',
          sortable: false,
          filterable: false,
          value: 'edit',
        },
        {
          text: 'Usuń',
          sortable: false,
          filterable: false,
          value: 'delete',
        },
      ],
      loadingItems: true,
      panelItems: undefined,

      sets: [],
      filtersSets: {
        search: '',
        category: -1,
      },
      headersSets: [
        {
          text: 'Nazwa przedmiotu',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Kategoria',
          sortable: true,
          filterable: false,
          value: 'category_id',
        },
        {
          text: 'Edytuj',
          sortable: false,
          filterable: false,
          value: 'edit',
        },
        {
          text: 'Usuń',
          sortable: false,
          filterable: false,
          value: 'delete',
        },
      ],
      loadingSets: true,
      panelSets: undefined,

      addCategoryDialog: false,
      editCategoryDialog: false,
      editedCategory: {
        category_id: null,
        name: '',
      },

      editingItem: null,
      addEditItemDialog: false,

      // addSetDialog: false,
      editingSet: null,
      addEditSetDialog: false,
    }),
    computed: {
      timeout: function () {
        return this.snackbarMessageList[0] && this.snackbarMessageList[0].timeout
          ? this.snackbarMessageList[0].timeout
          : 5000;
      },
      snackbarType: function () {
        return this.snackbarMessageList[0] && this.snackbarMessageList[0].type == 'error'
          ? 'red'
          : '';
      },
      snackbarButtonColor: function () {
        return this.snackbarMessageList[0] && this.snackbarMessageList[0].type == 'error'
          ? ''
          : 'amber';
      },
      searchCategories: function () {
        return JSON.stringify(this.filtersCategories);
      },
      searchItems: function () {
        return JSON.stringify(this.filtersItems);
      },
      searchSets: function () {
        return JSON.stringify(this.filtersSets);
      },
      categoriesData: function () {
        return this.filtersCategories.type == 'item'
          ? this.itemCategories.slice(1)
          : this.setCategories.slice(1);
      },
      itemCategoriesSelect: function () {
        return [
          {
            category_id: -1,
            name: 'Wszystkie',
            type: 'item',
            user_id: this.user.user_id,
          },
        ].concat(this.itemCategories);
      },
      setCategoriesSelect: function () {
        return [
          {
            category_id: -1,
            name: 'Wszystkie',
            type: 'set',
            user_id: this.user.user_id,
          },
        ].concat(this.setCategories);
      },
      permissions: function () {
        return {
          hard_furniture: 1,
          upholstered_furniture: 1,
          chairs: 1,
          tables: 1,

          paid_collection: 0,
        };
        // return {
        //   hard_furniture: this.user.hard_furniture || 1,
        //   upholstered_furniture: this.user.upholstered_furniture || 0,
        //   chairs: this.user.chairs || 0,
        //   tables: this.user.tables || 0,

        //   paid_collection: this.user.paid_collection || 0,
        // };
      },
    },
    watch: {
      panelCategories: function (val) {
        if (val !== undefined) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.$vuetify.goTo('#panelCategories');
            }, 100);
          });
        }
      },
      panelItems: function (val) {
        if (val !== undefined) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.$vuetify.goTo('#panelItems');
            }, 100);
          });
        }
      },
      panelSets: function (val) {
        if (val !== undefined) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.$vuetify.goTo('#panelSets');
            }, 100);
          });
        }
      },
      'snackbarMessageList.length': function (val) {
        if (val) {
          let that = this;
          setTimeout(function () {
            that.snackbar = true;
          }, 100);
        }
      },
      snackbar: function (val) {
        if (!val) {
          this.snackbarMessageList.shift();
        }
      },
      '$route.params.id': function (next, before) {
        if (before != null && next != before) {
          this.onCreated();
          this.onMounted();
        }
      },
      '$route.query.item': function (next, before) {
        if (next != before) {
          this.onMounted();
        }
      },
    },
    methods: {
      resetSnackbar: function () {
        this.snackbarMessageList = [];
        this.snackbar = false;
      },
      message: function (message) {
        this.snackbarMessageList.push(message);
      },

      // eslint-disable-next-line no-unused-vars
      customFilterCategories(value, search, item) {
        // const searchObject = JSON.parse(search);
        const searchObject = this.filtersCategories;
        const name = item.name.trim();
        const type = item.type;

        return (
          item.category_id != 0 &&
          (!searchObject.search ||
            name.toLowerCase().indexOf(searchObject.search.trim().toLowerCase()) !== -1) &&
          (!searchObject.type || type == searchObject.type)
        );
      },
      // eslint-disable-next-line no-unused-vars
      customFilterItems(value, search, item) {
        // const searchObject = JSON.parse(search);
        const searchObject = this.filtersItems;
        const name = item.name.trim();
        const category_id = item.category_id;

        return (
          (!searchObject.search ||
            name.toLowerCase().indexOf(searchObject.search.trim().toLowerCase()) !== -1) &&
          (searchObject.category == -1 ||
            (searchObject.category ? category_id == searchObject.category : category_id == null))
        );
      },
      // eslint-disable-next-line no-unused-vars
      customFilterSets(value, search, item) {
        // const searchObject = JSON.parse(search);
        const searchObject = this.filtersSets;
        const name = item.name.trim();
        const category_id = item.category_id;

        return (
          (!searchObject.search ||
            name.toLowerCase().indexOf(searchObject.search.trim().toLowerCase()) !== -1) &&
          (searchObject.category == -1 ||
            (searchObject.category ? category_id == searchObject.category : category_id == null))
        );
      },

      getCategories: function () {
        axios({
          url: this.$store.state.url + '/api/priceTable/get-categories',
          data: {
            token: this.$store.state.token,
            user_id: this.user.user_id,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status == 200) {
              this.itemCategories = resp.data.categories.items;
              this.setCategories = resp.data.categories.sets;
            } else
              this.message({
                content: resp.data.message,
                type: 'error',
              });

            this.loadingCategories = false;
          })
          .catch(() => {
            this.message({
              content: 'Nieoczekiwany błąd! Spróbuj później.',
              type: 'error',
            });
          });
      },
      getItems: function () {
        axios({
          url: this.$store.state.url + '/api/priceTable/get-items',
          data: {
            token: this.$store.state.token,
            user_id: this.user.user_id,
            extended: true,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status == 200) {
              this.items = resp.data.items;
            } else
              this.message({
                content: resp.data.message,
                type: 'error',
              });

            this.loadingItems = false;
          })
          .catch(() => {
            this.message({
              content: 'Nieoczekiwany błąd! Spróbuj później.',
              type: 'error',
            });
          });
      },
      getSets: function () {
        axios({
          url: this.$store.state.url + '/api/priceTable/get-sets',
          data: {
            token: this.$store.state.token,
            user_id: this.user.user_id,
            extended: true,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status == 200) {
              this.sets = resp.data.sets;
            } else
              this.message({
                content: resp.data.message,
                type: 'error',
              });

            this.loadingSets = false;
          })
          .catch(() => {
            this.message({
              content: 'Nieoczekiwany błąd! Spróbuj później.',
              type: 'error',
            });
          });
      },

      addOrEditItem: async function (item) {
        if (this.editingItem) await this.editItem(item);
        else await this.addItem(item);

        if (!this.addEditItemDialog) this.editingItem = null;
      },
      addOrEditSet: async function (set) {
        if (this.editingSet) await this.editSet(set);
        else await this.addSet(set);

        this.editingSet = null;
      },

      addCategory: function (category) {
        this.resetSnackbar();
        this.loadingProgress = true;

        axios({
          url: this.$store.state.url + '/api/priceTable/add-category',
          data: {
            token: this.$store.state.token,
            user_id: this.user.user_id,
            name: category.name,
            type: category.type,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status == 200) {
              if (category.type == 'item') this.itemCategories.push(resp.data.category);
              else this.setCategories.push(resp.data.category);

              this.addCategoryDialog = false;
              this.message({
                content: resp.data.message,
                type: 'info',
              });
            } else
              this.message({
                content: resp.data.message,
                type: 'error',
              });

            this.loadingProgress = false;
          })
          .catch(err => {
            if (err.response && err.response.status !== 500) {
              this.message({
                content: err.response.data.message || 'Nieoczekiwany błąd! Spróbuj później.',
                type: 'error',
              });
            } else {
              this.message({
                content: 'Nieoczekiwany błąd! Spróbuj później.',
                type: 'error',
              });
            }

            let that = this;
            setTimeout(function () {
              that.loadingProgress = false;
            }, 1000);
          });
      },
      addItem: async function (item) {
        this.resetSnackbar();
        this.loadingProgress = true;

        const result = await axios({
          url: this.$store.state.url + '/api/priceTable/add-item',
          data: {
            token: this.$store.state.token,
            user_id: this.user.user_id,
            item: item,
          },
          method: 'POST',
        }).catch(err => {
          if (err.response && err.response.status !== 500) {
            this.message({
              content: err.response.data.message || 'Nieoczekiwany błąd! Spróbuj później.',
              type: 'error',
            });
          } else {
            this.message({
              content: 'Nieoczekiwany błąd! Spróbuj później.',
              type: 'error',
            });
          }

          let that = this;
          setTimeout(function () {
            that.loadingProgress = false;
          }, 1000);
        });
        if (!result) return;

        if (result.data.status == 200) {
          this.items.push(result.data.item);

          this.message({
            content: result.data.message,
            type: 'info',
          });
          this.addEditItemDialog = false;
        } else
          this.message({
            content: result.data.message,
            type: 'error',
          });

        this.loadingProgress = false;
      },
      addSet: async function (set) {
        this.resetSnackbar();
        this.loadingProgress = true;

        const result = await axios({
          url: this.$store.state.url + '/api/priceTable/add-set',
          data: {
            token: this.$store.state.token,
            user_id: this.user.user_id,
            set: set,
          },
          method: 'POST',
        }).catch(err => {
          if (err.response && err.response.status !== 500) {
            this.message({
              content: err.response.data.message || 'Nieoczekiwany błąd! Spróbuj później.',
              type: 'error',
            });
          } else {
            this.message({
              content: 'Nieoczekiwany błąd! Spróbuj później.',
              type: 'error',
            });
          }

          let that = this;
          setTimeout(function () {
            that.loadingProgress = false;
          }, 1000);
        });
        if (!result) return;

        if (result.data.status == 200) {
          this.sets.push(result.data.set);

          this.message({
            content: result.data.message,
            type: 'info',
          });
          this.addEditSetDialog = false;
        } else
          this.message({
            content: result.data.message,
            type: 'error',
          });

        this.loadingProgress = false;
      },

      removeCategory: async function (category_id) {
        if (
          !(await this.$refs.confirmDialog.open(
            'Usuń kategorie',
            'Czy jesteś pewny, że chcesz usunąć kategorie?',
            { color: 'red' }
          ))
        ) {
          return;
        }

        this.resetSnackbar();
        this.loadingProgress = true;

        axios({
          url: this.$store.state.url + '/api/priceTable/remove-category',
          data: {
            token: this.$store.state.token,
            user_id: this.user.user_id,
            confirm: true,
            category_id: category_id,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status == 200) {
              if (this.filtersCategories.type == 'item') {
                for (let i = 0; i < this.itemCategories.length; i++) {
                  if (this.itemCategories[i].category_id == category_id) {
                    this.itemCategories.splice(i, 1);
                    break;
                  }
                }
                for (let i = 0; i < this.items.length; i++) {
                  if (this.items[i].category_id == category_id) {
                    this.items[i].category_id = null;
                  }
                }
              } else {
                for (let i = 0; i < this.setCategories.length; i++) {
                  if (this.setCategories[i].category_id == category_id) {
                    this.setCategories.splice(i, 1);
                    break;
                  }
                }
                for (let i = 0; i < this.sets.length; i++) {
                  if (this.sets[i].category_id == category_id) {
                    this.sets[i].category_id = null;
                  }
                }
              }

              this.message({
                content: resp.data.message,
                type: 'info',
              });
            } else
              this.message({
                content: resp.data.message,
                type: 'error',
              });

            this.loadingProgress = false;
          })
          .catch(err => {
            if (err.response && err.response.status !== 500) {
              this.message({
                content: err.response.data.message || 'Nieoczekiwany błąd! Spróbuj później.',
                type: 'error',
              });
            } else {
              this.message({
                content: 'Nieoczekiwany błąd! Spróbuj później.',
                type: 'error',
              });
            }

            let that = this;
            setTimeout(function () {
              that.loadingProgress = false;
            }, 1000);
          });
      },
      removeItem: async function (item_id) {
        if (
          !(await this.$refs.confirmDialog.open(
            'Usuń przedmiot',
            'Czy jesteś pewny, że chcesz usunąć przedmiot?',
            { color: 'red' }
          ))
        ) {
          return;
        }

        this.resetSnackbar();
        this.loadingProgress = true;

        axios({
          url: this.$store.state.url + '/api/priceTable/remove-item',
          data: {
            token: this.$store.state.token,
            user_id: this.user.user_id,
            confirm: true,
            item_id: item_id,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status == 200) {
              for (let i = 0; i < this.items.length; i++) {
                if (this.items[i].item_id == item_id) {
                  this.items.splice(i, 1);
                  break;
                }
              }

              for (let i = 0; i < resp.data.removedSets.length; i++) {
                for (let j = 0; j < this.sets.length; j++) {
                  if (this.sets[j].set_id == resp.data.removedSets[i]) {
                    this.sets.splice(j, 1);
                    break;
                  }
                }
              }

              for (let i = 0; i < this.sets.length; i++) {
                for (let j = 0; j < this.sets[i].items.length; j++) {
                  if (this.sets[i].items[j].item_id == item_id) {
                    this.sets[i].items.splice(j, 1);
                    break;
                  }
                }
              }

              this.message({
                content: resp.data.message,
                type: 'info',
              });
            } else
              this.message({
                content: resp.data.message,
                type: 'error',
              });

            this.loadingProgress = false;
          })
          .catch(err => {
            if (err.response && err.response.status !== 500) {
              this.message({
                content: err.response.data.message || 'Nieoczekiwany błąd! Spróbuj później.',
                type: 'error',
              });
            } else {
              this.message({
                content: 'Nieoczekiwany błąd! Spróbuj później.',
                type: 'error',
              });
            }

            let that = this;
            setTimeout(function () {
              that.loadingProgress = false;
            }, 1000);
          });
      },
      removeSet: async function (set_id) {
        if (
          !(await this.$refs.confirmDialog.open(
            'Usuń zestaw',
            'Czy jesteś pewny, że chcesz usunąć zestaw?',
            { color: 'red' }
          ))
        ) {
          return;
        }

        this.resetSnackbar();
        this.loadingProgress = true;

        axios({
          url: this.$store.state.url + '/api/priceTable/remove-set',
          data: {
            token: this.$store.state.token,
            user_id: this.user.user_id,
            confirm: true,
            set_id: set_id,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status == 200) {
              for (let i = 0; i < this.sets.length; i++) {
                if (this.sets[i].set_id == set_id) {
                  this.sets.splice(i, 1);
                  break;
                }
              }

              this.message({
                content: resp.data.message,
                type: 'info',
              });
            } else
              this.message({
                content: resp.data.message,
                type: 'error',
              });

            this.loadingProgress = false;
          })
          .catch(err => {
            if (err.response && err.response.status !== 500) {
              this.message({
                content: err.response.data.message || 'Nieoczekiwany błąd! Spróbuj później.',
                type: 'error',
              });
            } else {
              this.message({
                content: 'Nieoczekiwany błąd! Spróbuj później.',
                type: 'error',
              });
            }

            let that = this;
            setTimeout(function () {
              that.loadingProgress = false;
            }, 1000);
          });
      },

      editCategory: function (category) {
        this.resetSnackbar();
        this.loadingProgress = true;

        axios({
          url: this.$store.state.url + '/api/priceTable/edit-category',
          data: {
            token: this.$store.state.token,
            user_id: this.user.user_id,
            name: category.name,
            category_id: category.category_id,
          },
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status == 200) {
              if (category.type == 'item') {
                for (let i = 0; i < this.itemCategories.length; i++) {
                  if (this.itemCategories[i].category_id == category.category_id) {
                    this.itemCategories[i].name = category.name;
                    break;
                  }
                }
              } else {
                for (let i = 0; i < this.setCategories.length; i++) {
                  if (this.setCategories[i].category_id == category.category_id) {
                    this.setCategories[i].name = category.name;
                    break;
                  }
                }
              }

              this.editCategoryDialog = false;
              this.message({
                content: resp.data.message,
                type: 'info',
              });
            } else
              this.message({
                content: resp.data.message,
                type: 'error',
              });

            this.loadingProgress = false;
          })
          .catch(err => {
            if (err.response && err.response.status !== 500) {
              this.message({
                content: err.response.data.message || 'Nieoczekiwany błąd! Spróbuj później.',
                type: 'error',
              });
            } else {
              this.message({
                content: 'Nieoczekiwany błąd! Spróbuj później.',
                type: 'error',
              });
            }

            let that = this;
            setTimeout(function () {
              that.loadingProgress = false;
            }, 1000);
          });
      },
      editItem: async function (item) {
        this.resetSnackbar();
        this.loadingProgress = true;

        const result = await axios({
          url: this.$store.state.url + '/api/priceTable/edit-item',
          data: {
            token: this.$store.state.token,
            user_id: this.user.user_id,
            item: item,
          },
          method: 'POST',
        }).catch(err => {
          if (err.response && err.response.status !== 500) {
            this.message({
              content: err.response.data.message || 'Nieoczekiwany błąd! Spróbuj później.',
              type: 'error',
            });
          } else {
            this.message({
              content: 'Nieoczekiwany błąd! Spróbuj później.',
              type: 'error',
            });
          }

          let that = this;
          setTimeout(function () {
            that.loadingProgress = false;
          }, 1000);
        });
        if (!result) return;

        if (result.data.status == 200) {
          Object.assign(
            this.items.find(elem => elem.item_id == result.data.item.item_id),
            result.data.item
          );

          this.message({
            content: result.data.message,
            type: 'info',
          });
          this.addEditItemDialog = false;
        } else
          this.message({
            content: result.data.message,
            type: 'error',
          });

        this.loadingProgress = false;
      },
      editSet: async function (set) {
        this.resetSnackbar();
        this.loadingProgress = true;

        const result = await axios({
          url: this.$store.state.url + '/api/priceTable/edit-set',
          data: {
            token: this.$store.state.token,
            user_id: this.user.user_id,
            set: set,
          },
          method: 'POST',
        }).catch(err => {
          if (err.response && err.response.status !== 500) {
            this.message({
              content: err.response.data.message || 'Nieoczekiwany błąd! Spróbuj później.',
              type: 'error',
            });
          } else {
            this.message({
              content: 'Nieoczekiwany błąd! Spróbuj później.',
              type: 'error',
            });
          }

          let that = this;
          setTimeout(function () {
            that.loadingProgress = false;
          }, 1000);
        });
        if (!result) return;

        if (result.data.status == 200) {
          Object.assign(
            this.sets.find(elem => elem.set_id == result.data.set.set_id),
            result.data.set
          );

          this.message({
            content: result.data.message,
            type: 'info',
          });
          this.addEditSetDialog = false;
        } else
          this.message({
            content: result.data.message,
            type: 'error',
          });

        this.loadingProgress = false;
      },

      editItemButton: function (item) {
        this.editingItem = item;
        this.addEditItemDialog = true;
      },
      editSetButton: function (set) {
        this.editingSet = set;
        this.addEditSetDialog = true;
      },
      onCreated: function () {
        this.isAdmin = this.$store.getters.isAdmin;

        this.user.user_id = this.$route.params?.id
          ? decodeURIComponent(this.$route.params.id)
          : this.$store.getters.identifier;

        const data = {
          token: this.$store.state.token,
          type: 'price',
        };
        if (this.user.user_id && this.isAdmin) data.params = { id: this.user.user_id };

        axios({
          url: this.$store.state.url + '/api/users/get/customers',
          data: data,
          method: 'POST',
        })
          .then(resp => {
            this.user = {
              user_id: resp.data.users[0].id,
              name: resp.data.users[0].name,
              surname: resp.data.users[0].surname,
              phone: resp.data.users[0].phone,
              email: resp.data.users[0].email,

              hard_furniture: resp.data.users[0].hard_furniture,
              upholstered_furniture: resp.data.users[0].upholstered_furniture,
              chairs: resp.data.users[0].chairs,
              tables: resp.data.users[0].tables,

              paid_collection: resp.data.users[0].paid_collection,
            };
          })
          .catch(err => {
            if (err.response && err.response.status !== 500) {
              this.message({
                content: err.response.data.message || 'Nieoczekiwany błąd! Spróbuj później.',
                type: 'error',
              });
            } else {
              this.message({
                content: 'Nieoczekiwany błąd! Spróbuj później.',
                type: 'error',
              });
            }

            let that = this;
            setTimeout(function () {
              that.loadingProgress = false;
            }, 1000);
          });
      },
      onMounted: function () {
        this.loadingItems = true;
        this.getCategories();
        this.getItems();
        this.getSets();

        if (this.$route.query?.item) {
          const that = this;
          // eslint-disable-next-line no-inner-declarations
          function waitForItems() {
            if (that.loadingItems) setTimeout(waitForItems, 100);
            else {
              that.panelItems = 0;
              const foundItem = that.items.find(
                elem => elem.item_id == decodeURIComponent(that.$route.query.item)
              );
              if (foundItem) that.editItemButton(foundItem);
            }
          }
          waitForItems();
        }
      },
    },
    mounted() {
      this.onMounted();
    },
    created() {
      this.onCreated();
    },
  };
</script>
<style scoped>
  .v-list-item__title {
    font-size: 1.5rem;
  }

  .panel-header-flex-fix {
    flex: 0 0 auto;
  }
</style>
