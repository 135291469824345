var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"grey lighten-4 bottom-spacer",staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('v-card-title',{staticClass:"px-0"},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cennik")]),_c('v-list-item-subtitle',[_vm._v("Definiuj przedmioty oraz zestawy")])],1)],1)],1),(_vm.isAdmin)?_c('v-card',{staticClass:"mx-auto mb-10",attrs:{"color":"grey darken-4","dark":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"overline mb-4"},[_vm._v("Dane klienta")]),_c('v-list-item-title',{staticClass:"headline mb-1"},[_vm._v(" "+_vm._s(_vm.user.name)+" "+_vm._s(_vm.user.surname)+" ")]),_c('v-list-item-subtitle',{staticClass:"mt-3"},[_vm._v(" Telefon: "+_vm._s(_vm.user.phone)+" ")]),_c('v-list-item-subtitle',{staticClass:"mt-3"},[_vm._v(" Email: "+_vm._s(_vm.user.email)+" ")])],1),_c('v-list-item-avatar',{attrs:{"size":"80","color":"amber"}},[_c('span',{staticClass:"white--text headline"},[_vm._v(" "+_vm._s(_vm.user.name.substr(0, 1))+_vm._s(_vm.user.surname.substr(0, 1))+" ")])])],1)],1):_vm._e(),_c('v-expansion-panels',{attrs:{"id":"panelCategories"},model:{value:(_vm.panelCategories),callback:function ($$v) {_vm.panelCategories=$$v},expression:"panelCategories"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"headline font-weight-bold"},[_vm._v(" Kategorie "),_c('v-tooltip',{attrs:{"top":"","max-width":"460"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 panel-header-flex-fix",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(" Dodaj kategorie do listy kategorii, aby móc korzystać z niej podczas dodawania przedmiotów lub zestawów. ")])])],1),_c('v-expansion-panel-content',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersCategories,"items":_vm.categoriesData,"item-key":"category_id","search":_vm.searchCategories,"custom-filter":_vm.customFilterCategories,"loading":_vm.loadingCategories,"loading-text":_vm.loading_text,"footer-props":_vm.footerProps,"sortBy":"name","sortDesc":false},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mx-auto",attrs:{"align":"center"}},[_c('v-col',{attrs:{"lg":"4","md":"4","sm":"4","cols":"12"}},[_c('v-btn',{staticClass:"mx-2 mt-auto",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.addCategoryDialog = true}}},[_vm._v(" Dodaj kategorię ")]),_c('v-spacer')],1),_c('v-col',{attrs:{"lg":"3","md":"3","sm":"3","cols":"12"}},[_c('v-select',{attrs:{"items":_vm.categoryTypesSelect,"label":"Typ","hide-details":"","item-text":"name","item-value":"type","dense":""},model:{value:(_vm.filtersCategories.type),callback:function ($$v) {_vm.$set(_vm.filtersCategories, "type", $$v)},expression:"filtersCategories.type"}})],1),_c('v-col',{attrs:{"lg":"5","md":"5","sm":"5","cols":"12"}},[_c('v-spacer'),_c('v-text-field',{staticClass:"mx-2 pb-3",attrs:{"append-icon":"mdi-magnify","label":"Szukaj (nazwa kategorii)","single-line":"","hide-details":""},model:{value:(_vm.filtersCategories.search),callback:function ($$v) {_vm.$set(_vm.filtersCategories, "search", $$v)},expression:"filtersCategories.search"}})],1)],1)]},proxy:true},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"blue","outlined":""},on:{"click":function($event){_vm.editedCategory = item;
                _vm.editCategoryDialog = true;}}},[_vm._v(" Edytuj ")])]}},{key:"item.delete",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.removeCategory(item.category_id)}}},[_vm._v(" Usuń ")])]}}],null,true)})],1)],1)],1),_c('br'),_c('v-expansion-panels',{attrs:{"id":"panelItems"},model:{value:(_vm.panelItems),callback:function ($$v) {_vm.panelItems=$$v},expression:"panelItems"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"headline font-weight-bold"},[_vm._v(" Przedmioty "),_c('v-tooltip',{attrs:{"top":"","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 panel-header-flex-fix",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(" Dodaj przedmiot do listy przedmiotów, aby móc korzystać z niego podczas dodawania zlecenia. Cena za transport przedmiotu zostanie wyznaczona przez administratora, mimo to system pozwala zlecać transport na przedmiotu jeszcze nie wycenione. ")])])],1),_c('v-expansion-panel-content',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersItems,"items":_vm.items,"item-key":"item_id","search":_vm.searchItems,"custom-filter":_vm.customFilterItems,"loading":_vm.loadingItems,"loading-text":_vm.loading_text,"footer-props":_vm.footerProps,"sortBy":"name","sortDesc":false},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mx-auto",attrs:{"align":"center"}},[_c('v-col',{attrs:{"lg":"4","md":"4","sm":"4","cols":"12"}},[_c('v-btn',{staticClass:"mx-2 mt-auto",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.addEditItemDialog = true}}},[_vm._v(" Dodaj przedmiot ")]),_c('v-spacer')],1),_c('v-col',{attrs:{"lg":"3","md":"3","sm":"3","cols":"12"}},[_c('v-select',{attrs:{"items":_vm.itemCategoriesSelect,"label":"Kategoria","hide-details":"","item-text":"name","item-value":"category_id","loading":_vm.loadingCategories,"dense":""},model:{value:(_vm.filtersItems.category),callback:function ($$v) {_vm.$set(_vm.filtersItems, "category", $$v)},expression:"filtersItems.category"}})],1),_c('v-col',{attrs:{"lg":"5","md":"5","sm":"5","cols":"12"}},[_c('v-spacer'),_c('v-text-field',{staticClass:"mx-2 pb-3",attrs:{"append-icon":"mdi-magnify","label":"Szukaj (nazwa przedmiotu)","single-line":"","hide-details":""},model:{value:(_vm.filtersItems.search),callback:function ($$v) {_vm.$set(_vm.filtersItems, "search", $$v)},expression:"filtersItems.search"}})],1)],1)]},proxy:true},{key:"item.category_id",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.itemCategories.find(function (el) { return el.category_id == item.category_id; }).name)+" ")]}},{key:"item.edit",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{attrs:{"color":"blue","outlined":""},on:{"click":function($event){return _vm.editItemButton(item)}}},[_vm._v(" Edytuj ")]),(!item.isAccepted)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-currency-usd ")])],1)]}}],null,true)},[(_vm.isAdmin)?_c('span',[_vm._v(" Cena niezweryfikowana przez administratora. Aby zatwierdzić cenę należy edytować przedmiot i kliknąć przycisk edytuj ")]):_c('span',[_vm._v("Cena niezweryfikowana przez administratora")])]):_vm._e()]}},{key:"item.delete",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.removeItem(item.item_id)}}},[_vm._v(" Usuń ")])]}}],null,true)})],1)],1)],1),_c('br'),_c('v-expansion-panels',{attrs:{"id":"panelSets"},model:{value:(_vm.panelSets),callback:function ($$v) {_vm.panelSets=$$v},expression:"panelSets"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"headline font-weight-bold"},[_vm._v(" Zestawy "),_c('v-tooltip',{attrs:{"top":"","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 panel-header-flex-fix",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(" Dodaj przedmioty do zestawów, aby móc przyśpieszyć składanie zamówień (podczas składania zamówienia będziesz mógł wybrać wariant dla każdego przedmiotu). ")])])],1),_c('v-expansion-panel-content',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersSets,"items":_vm.sets,"item-key":"item_id","search":_vm.searchSets,"custom-filter":_vm.customFilterSets,"loading":_vm.loadingSets,"loading-text":_vm.loading_text,"footer-props":_vm.footerProps,"sortBy":"name","sortDesc":false},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mx-auto",attrs:{"align":"center"}},[_c('v-col',{attrs:{"lg":"4","md":"4","sm":"4","cols":"12"}},[_c('v-btn',{staticClass:"mx-2 mt-auto",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.addEditSetDialog = true}}},[_vm._v(" Dodaj zestaw ")]),_c('v-spacer')],1),_c('v-col',{attrs:{"lg":"3","md":"3","sm":"3","cols":"12"}},[_c('v-select',{attrs:{"items":_vm.setCategoriesSelect,"label":"Kategoria","hide-details":"","item-text":"name","item-value":"category_id","loading":_vm.loadingCategories,"dense":""},model:{value:(_vm.filtersSets.category),callback:function ($$v) {_vm.$set(_vm.filtersSets, "category", $$v)},expression:"filtersSets.category"}})],1),_c('v-col',{attrs:{"lg":"5","md":"5","sm":"5","cols":"12"}},[_c('v-spacer'),_c('v-text-field',{staticClass:"mx-2 pb-3",attrs:{"append-icon":"mdi-magnify","label":"Szukaj (nazwa zestawu)","single-line":"","hide-details":""},model:{value:(_vm.filtersSets.search),callback:function ($$v) {_vm.$set(_vm.filtersSets, "search", $$v)},expression:"filtersSets.search"}})],1)],1)]},proxy:true},{key:"item.category_id",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setCategories.find(function (el) { return el.category_id == item.category_id; }).name)+" ")]}},{key:"item.edit",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{attrs:{"color":"blue","outlined":""},on:{"click":function($event){return _vm.editSetButton(item)}}},[_vm._v(" Edytuj ")])]}},{key:"item.delete",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.removeSet(item.set_id)}}},[_vm._v(" Usuń ")])]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.loadingProgress),callback:function ($$v) {_vm.loadingProgress=$$v},expression:"loadingProgress"}},[_c('v-container',{staticStyle:{"background-color":"rgba(255, 255, 255, 0.5)"},attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"amber"}})],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbarType,"multi-line":true,"timeout":_vm.timeout,"content-class":"d-inline-flex"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessageList[0] ? _vm.snackbarMessageList[0].content : '')+" "),_c('v-spacer'),_c('v-btn',{attrs:{"color":_vm.snackbarButtonColor,"text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v(" Zamknij ")])],1),(_vm.addCategoryDialog)?_c('AddCategory',{on:{"deactive":function($event){_vm.addCategoryDialog = false;
      _vm.resetSnackbar();},"message":_vm.message,"add":_vm.addCategory}}):_vm._e(),(_vm.editCategoryDialog)?_c('EditCategory',{attrs:{"category":_vm.editedCategory},on:{"deactive":function($event){_vm.editCategoryDialog = false;
      _vm.resetSnackbar();},"message":_vm.message,"edit":_vm.editCategory}}):_vm._e(),_c('AddEditItemDialog',{attrs:{"categories":_vm.itemCategories,"item":_vm.editingItem,"permissions":_vm.permissions},on:{"input":function($event){_vm.editingItem = null},"submit":_vm.addOrEditItem},model:{value:(_vm.addEditItemDialog),callback:function ($$v) {_vm.addEditItemDialog=$$v},expression:"addEditItemDialog"}}),_c('AddEditSetDialog',{attrs:{"set":_vm.editingSet,"categories":_vm.setCategories,"items":_vm.items},on:{"input":function($event){_vm.editingSet = null},"submit":_vm.addOrEditSet},model:{value:(_vm.addEditSetDialog),callback:function ($$v) {_vm.addEditSetDialog=$$v},expression:"addEditSetDialog"}}),_c('ConfirmDialog',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }