<template>
  <v-container class="pa-0">
    <v-form ref="setForm" v-model="setForm">
      <v-row>
        <v-col lg="8" md="8" sm="8" cols="12">
          <v-text-field
            v-model="setCopy.name"
            label="Nazwa zestawu"
            :rules="lengthRule(1, 128)"
            maxlength="128"
          ></v-text-field>
        </v-col>

        <v-col lg="4" md="4" sm="4" cols="12" class="pt-3 pb-4">
          <v-select
            v-model="setCopy.category_id"
            :items="categories"
            label="Kategoria"
            hide-details
            item-text="name"
            item-value="category_id"
          ></v-select>
        </v-col>

        <!-- v-slot extra positions for item -->
      </v-row>
    </v-form>

    <v-divider></v-divider>
    <v-divider></v-divider>

    <v-form ref="itemsForm" v-model="itemsForm">
      <v-row
        v-for="(item, index) in setCopy.items"
        :key="index"
        justify="center"
        align="center"
        class="pt-4"
      >
        <v-col class="v-col--fit-content pr-0" style="min-width: 100px">
          <v-row no-gutters justify="center" align="center">
            <v-text-field
              v-model="item.quantity"
              type="number"
              min="1"
              max="9999"
              label="Ilość"
              :rules="integer(1, 4)"
              dense
              outlined
            ></v-text-field>
            <span class="mb-3 pb-3 px-2">&times;</span>
          </v-row>
        </v-col>

        <v-col class="px-0">
          <v-autocomplete
            v-model="setCopy.items[index].item_id"
            label="Wybierz przedmiot"
            :rules="[v => !!v || 'Wybierz przedmiot']"
            :items="autoCompleteItemsArray(index)"
            item-value="item_id"
            :item-text="autoCompleteTextFormater"
            dense
            outlined
            hide-selected
          >
          </v-autocomplete>
        </v-col>

        <v-col class="v-col--fit-content">
          <v-btn icon color="error" small class="mb-3 pb-3" @click="removeItem(item, index)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-row class="mx-4 my-4 pa-auto">
      <v-btn block @click="addItem()" x-large dark color="primary" class="mx-auto">
        <v-icon left>mdi-plus</v-icon> Dodaj przedmiot
      </v-btn>
    </v-row>

    <!-- confirm dialog -->
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>

    <!-- snackbar -->
    <InfoSnackBar v-model="info"></InfoSnackBar>
  </v-container>
</template>

<script>
  import ConfirmDialog from '@/components/Confirm.vue';
  import InfoSnackBar from '@/components/InfoSnackBar.vue';

  export default {
    name: 'AddEditSetComponent',
    components: {
      ConfirmDialog,
      InfoSnackBar,
    },
    props: {
      set: {
        type: Object,
      },
      items: {
        type: Array,
        required: true,
      },
      categories: {
        type: Array,
        required: true,
      },
    },
    data: () => ({
      info: null,

      setForm: true,
      setCopy: { items: [] },

      itemsForm: true,
    }),
    watch: {
      set: function () {
        this.initSet();
      },
    },
    computed: {
      isValid: function () {
        return this.setForm && this.itemsForm;
      },
    },
    methods: {
      initSet: function () {
        this.setCopy = this.set
          ? JSON.parse(JSON.stringify(this.set))
          : {
              name: '',
              category_id: null,
              items: [this.getEmptyItem()],
            };
      },
      getEmptyItem: function () {
        return {
          name: '',
          item_id: null,
          category_id: null,
          item_type: '',
          variants: [],
          quantity: 1,
        };
      },
      addItem: function () {
        this.setCopy.items.push(this.getEmptyItem());
      },
      removeItem: async function (item, index) {
        if (
          item.item_id &&
          !(await this.$refs.confirmDialog.open(
            'Usuń przedmiot',
            `Czy jesteś pewien, że chcesz usunąć przedmiot?`,
            { color: 'red' }
          ))
        )
          return;

        this.setCopy.items.splice(index, 1);

        // set is empty so fix it
        if (!this.setCopy.items.length) {
          this.addItem();
          this.infoSnackBar({
            message: 'Zestaw nie może być pusty, wybierz przynajmniej jeden przedmiot',
            status: 'error',
          });
        }
      },
      itemTypeMapper(itemType) {
        switch (itemType) {
          case 'hard_furniture':
            return 'meble w paczkach';
          case 'upholstered_furniture':
            return 'meble tapicerowane';
          case 'chairs':
            return 'krzesła';
          case 'tables':
            return 'stoły';
          default:
            return 'meble w paczkach';
        }
      },
      lengthRule: function (min_length, max_length) {
        return [
          v =>
            new RegExp(`^.{${min_length},${max_length}}$`).test(v) ||
            `Pole musi zawierac od ${min_length} do ${max_length} znaków`,
        ];
      },
      integer: function (from, to, required = true, positive = true, notZero = true) {
        const rules = [];

        if (required) {
          rules.push(v => !!v || 'Pole jest wymagane');
          rules.push(v => new RegExp(`^(0|[1-9]\\d*)$`).test(v) || 'Zły format liczby');
          rules.push(
            v =>
              new RegExp(`^.{${from},${to}}$`).test(v) ||
              `Liczba musi zawierac od ${from} do ${to} cyfr`
          );
        } else {
          rules.push(v => new RegExp(`^$|^(0|[1-9]\\d*)$`).test(v) || 'Zły format liczby');
          rules.push(
            v =>
              new RegExp(`^$|^.{${from},${to}}$`).test(v) ||
              `Liczba musi zawierac od ${from} do ${to} cyfr`
          );
        }

        if (positive) {
          rules.push(v => v >= 0 || 'Pole musi być dodatnią liczbą');
        }

        if (notZero) {
          rules.push(v => v != 0 || 'Pole musi być różne od zera');
        }

        return rules;
      },
      autoCompleteTextFormater: function (item) {
        return item?.item_id ? `${item.name} (${this.itemTypeMapper(item.item_type)})` : '';
      },
      autoCompleteItemsArray: function (index) {
        return this.items.filter(
          elem =>
            !this.setCopy.items
              .filter((_, elemIndex) => elemIndex != index)
              .map(elem => elem.item_id)
              .includes(elem.item_id)
        );
      },
      infoSnackBar(info) {
        this.info = info;
      },
    },
    mounted() {
      this.initSet();
    },
  };
</script>

<style>
  div.v-select__slot div.v-input__append-inner {
    align-self: auto;
  }
</style>
<style scoped>
  .v-btn--outlined {
    border: thin solid #ffc400 !important;
  }
  .v-col--fit-content {
    min-width: fit-content;
    max-width: fit-content;
  }
</style>
